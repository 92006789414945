<template>
  <div>
    <v-app-bar
      id="home-app-bar"
      app
      color="grey lighten-3"
      elevation="1"
      height="50"
    >
      <!-- <base-img
        :src="require('@/assets/logo-big-atu.png')"
        class="mr-2"
        contain
        max-width="200"
        width="100%"
      /> -->
      <!-- <span class="font-weight-bold primary--text hidden-sm-and-down">#MantapBerhijrah</span> -->
      <v-spacer />

      <div>
        <v-tabs
          class="hidden-sm-and-down"
          optional
          background-color = "grey lighten-3"
        >
          <v-tab
            v-for="data in items"
            :key="data.id"
            @click="data.child.length === 0 ? URL(data.id) : ''"
            :ripple = "false"
            class="font-weight-bold text-capitalize"
            min-width="96"
            text
          >
          <v-menu 
          offset-y
          open-on-hover
          :close-on-content-click="true"
          transition="slide-y-transition"
          >
          <template v-slot:activator="{ on, attrs }">
           <v-icon 
           color ="secondary"
           left 
           v-if="data.id === 'login'">
          mdi-shield-account-variant
          </v-icon> <span v-bind="attrs"
            v-on="on">{{ data.title }} <v-icon v-if="data.child.length > 0">mdi-menu-down</v-icon></span>
          </template>
          <v-list
            v-if="data.child.length > 0"
            dense
            class="mt-4">
            <v-list-item
              v-for="(item, index) in data.child"
              :key="index"
              @click = "URL(item.id)"
            >
              <v-list-item-title><v-icon>{{ item.icon }}</v-icon> {{ item.title }}</v-list-item-title>
            </v-list-item>
          </v-list>
          </v-menu>
          </v-tab>
        </v-tabs>
      </div>

      <v-app-bar-nav-icon
        class="hidden-md-and-up"
        @click="drawer = !drawer"
        icon
      >
      <v-icon>mdi-menu</v-icon>
      </v-app-bar-nav-icon>
    </v-app-bar>

    <home-drawer
      v-model="drawer"
      :items="items"
      @close="drawer=false"
    />
  </div>
</template>

<script>
  export default {
    name: 'HomeAppBar',

    components: {
      HomeDrawer: () => import('./Drawer'),
    },

    data: () => ({
      drawer: null,
      items: [
        { id: 'beranda', title: 'Beranda', child: [] },
        { id: 'tentang', title: 'Tentang Kami', child: [
          {id: 'tentang-kami', title: 'Visi Misi', icon:'mdi-bullseye'},
          {id: 'struktur-perusahaan', title: 'Struktur', icon:'mdi-sitemap'},
          {id: 'mekanisme-penjaminan', title: 'Mekanisme Penjaminan', icon:'mdi-clipboard-flow-outline'},
          {id: 'sejarah-orionpenjaminan', title: 'Sejarah', icon:'mdi-domain'},
          {id: 'produk', title: 'Produk', icon:'mdi-shield-check-outline'},
          // {id: 'berita-lainnya', title: 'Ikuti Berita kami', icon:'mdi-newspaper-variant-outline'},
          // {id: 'penghargaan-perusahaan', title: 'Penghargaan', icon:'mdi-trophy-outline'},
          // {id: 'laporan-keuangan', title: 'Laporan Keuangan', icon:'mdi-finance'},
        ]},
        { id: 'layanan', title: 'Layanan', child: [
          // { id: 'pengaduan', title: 'Pengaduan Peserta', icon:'mdi-format-quote-close-outline' },
          {id: 'jaringan', title: 'Jaringan', icon:'mdi-store-outline'},
          // {id: 'faq', title: 'FAQ', icon:'mdi-message-question-outline'},
        ]},
        // { id: 'wbs', title: 'Whistle Blowing', child: [] },
        // { id: 'karir', title: 'Karir', child: [] },
        // { id: 'login', title: 'AkunKu', child: [] },
      ],
      ShowMenu: false,
    }),
    methods: {
      URL (id) {
        if (id !== '') {
          this.$router.push({name: id}).catch(()=>{})
        }
      },
    }
  }
</script>

<style lang="sass">
  #home-app-bar
    .v-tabs-slider
      max-width: 24px
      margin: 0 auto

    .v-tab
      &::before
        display: none
</style>
